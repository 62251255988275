.custom_walls {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  perspective: 10em;
  perspective-origin: 50% calc(50% - 2em);
  overflow: hidden;

  img {
    position: absolute;
    z-index: 100;
  }

  .scene {
    position: relative;
    transform-style: preserve-3d;

    .frontWall {
      position: absolute;
      width: 20em;
      height: 20em;
      top: -16em;
      left: -10em;
      transform: translateZ(-10em);
    }

    .rightWall {
      @extend .frontWall;
      left: 0;
      transform: rotateY(90deg);
    }

    .leftWall {
      @extend .frontWall;
      left: -20em;
      transform: rotateY(-90deg);
    }

    .floor {
      background-position: 1em 0, 1em 0, 0 0, 0 0;
      background-size: 1em 1em;
      background-repeat: repeat;
      width: 20em;
      height: 20em;
      top: 1em;
      z-index: 0;
      position: absolute;
      transform: translate(-50%, -50%) rotateX(90deg) translateZ(-3em);
    }

    .ceiling {
      width: 20em;
      height: 20em;
      top: 5em;
      left: 0;
      position: absolute;
      transform: translate(-50%, -50%) rotateX(90deg) translateZ(15em);
    }
  }
}

.category_img {
  height: auto;
  margin: 30px auto;
  position: relative;
  z-index: 0;
  overflow: hidden;

  img.main_img {
    z-index: 99;
    position: relative;
  }

  .pattern_change {
    opacity: .5;
    mix-blend-mode: hard-light;
    filter: drop-shadow(-1px 1px 0px rgba(255, 255, 255, .6));
    z-index: 9;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 972px;
    height: 608px;


  }

}

.color_mainbox ul::-webkit-scrollbar, .select_products ul::-webkit-scrollbar {
  width: 0px;
}


.custom_Sound_Pannel {
  margin-top: 7%;

  .container {
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
    }

    .Choose_color {
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }

      .column_color {
        box-sizing: border-box;
        background: #ffffff;
        border: 1px solid #9e9e9e;
        border-radius: 5px;
        padding: 10px;
      }
    }
  }
}

.color_mainbox {
  ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex: 1 1;
    overflow: auto;
    margin-bottom: 40px;
    max-height: 400px;
    flex-wrap: wrap;
    list-style: none;

    li {
      height: 67px;
      flex: 0 0 100px;
      margin-bottom: 10px
    }
  }

}

.Choose_color {
  .content {
    .side_content {
      Button {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #535967;
        background-color: #fff;
        border: none;
        border-bottom: #000;
      }

      Button:hover {
        color: #fff;
        background-color: #030760;
        border-color: #fff;
      }

      .btn_select {
        color: #a7b241;
        font-weight: 600;
        border-bottom: 1px solid #a7b241;
      }
    }
  }
}

.select_products {
  ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    height: 400px;
    overflow: auto;
    margin-bottom: 40px;

    li {
      height: auto;
      flex: 0 0 32%;
      margin-bottom: 2%;
      height: 200px;

      img {
        width: 100%;
        height: 70%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

canvas {
  width: 100% !important;
  height: 615px !important;
}

.main_div.home_oofice {
  width: 100%;
  max-width: 984px;
}

.custom_Sound_Pannel {
  .container {
    .Choose_color {
      .select_products {
        .product_name {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #000000;
        }
      }
    }
  }


}


@media screen and (max-device-width: 1024px) {
  .color_mainbox {
    ul {
      li {
        height: 37px;
      }
    }
  }
  .custom_walls {
    .scene {
      .ceiling {
        top: 9em;
      }
    }
  }
  .Choose_color {
    .content {
      .side_content {
        Button {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 820px) {
  .select_products {
    ul {
      li {
        img {
          width: 100%;
          height: 50%;
        }
      }
    }
  }
}

@media screen and (max-device-width: 320px) {
  .custom_Sound_Pannel {
    .container {
      .Choose_color {
        .select_products {
          .product_name {
            font-size: 10px;
            line-height: 10px;
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 375px) {
  .Choose_color {
    .content {
      .side_content {
        display: flex;

        Button {
          font-size: 11px;
        }
      }
    }
  }
}

// For Walls
@media screen and (max-device-width: 1440px) {
  .custom_walls {
    .scene {
      .ceiling {
        top: 7.5em;
      }
    }
  }
}
