.headerimg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 20;
    .overlay {
        width: 100%;
        height: 820px;
        position: relative;
    }
    .over {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 820px;
        background: linear-gradient(259.13deg, rgba(0, 0, 0, 0) 17.64%, rgba(0, 0, 0, 0.5) 49.38%);
    }
    .imgtopheading {
        .container {
            max-width: 1710px;
        }
        p {
            position: absolute;
            width: 716px;
            height: 225px;
            left: 105px;
            top: 572px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 56px;
            line-height: 75px;
            color: #ffffff;
        }
    }
}
@media screen and (min-width: 2560px) {
    .headerimg {
        .imgtopheading {
            p {
                left: 410px;
            }
        }
    }
}
@media screen and (min-device-width: 1700px) and (max-device-width: 2560px) {
    .container {
        max-width: 1710px;
    }
}

@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
    .container {
        max-width: 1710px;
    }
}
@media screen and (max-width: 1024px) {
    .headerimg {
        .imgtopheading {
            p {
                width: 516px;
                font-size: 41px;
                line-height: 55px;
            }
        }
    }
}
@media screen and (max-width: 1440px) {
    .headerimg {
        .imgtopheading {
            p {
                left: 15px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .headerimg {
        .imgtopheading {
            p {
                width: 416px;
                font-size: 35px;
                line-height: 45px;
                left: 15px;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .headerimg {
        .imgtopheading {
            p {
                width: 316px;
                font-size: 25px;
                line-height: 35px;
                left: 15px;
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .headerimg {
        .imgtopheading {
            p {
                width: 290px;
            }
        }
    }
}
