.footer {
  margin-top: 7%;
  background: rgba(240, 240, 240, 0.5);
  padding: 70px;

  .container {
    max-width: 1710px;

    .first_col {
      .image_logo {
        // img {
        //     width: 260px;
        //     height: 74.98px;
        // }
      }

      .legal {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #535967;
      }

      .Privacy {
        @extend .legal;
      }
    }

    .second_col {
      ul {
        list-style: none;

        li {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 10px;

          h3 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            margin-bottom: 20px;
          }
        }
      }
    }

    .third_col {
      margin-top: 0px;

      h3 {
        margin-top: 0px;
        margin-bottom: 20px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }

      .details {
        width: 220px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }

      .contact {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }

      .email {
        @extend .contact;
      }

      .icons {
        .space {
          padding-top: 1px;
          margin-right: 10px;
        }
      }
    }

    .fourth_col {
      .data {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        color: #000000;
      }

      .inputhere {
        margin-bottom: 10%;

        .inputcheck {
          border-top: none;
          border-left: none;
          border-right: none;
          background: rgba(240, 240, 240, 0.5);
        }
      }

      .side_btn {
        padding: 5px 15px 5px 15px;
        background: #000000;
        border-radius: 20px;
        border: none;

        .icon {
          margin-left: 10px;
        }
      }
    }

    .theend {
      padding-top: 100px;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        text-align: right;
        color: #535967;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    .container {
      .fourth_col {
        .side_btn {
          padding: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 912px) {
  .footer {
    .container {
      .second_col {
        ul {
          padding: 0px;
        }
      }

      .for_space {
        margin-top: 100px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .footer {
    .container {
      .theend {
        .theend_div {
          p {
            font-size: 10px;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .footer {
    margin-top: 7%;
    background: rgba(240, 240, 240, 0.5);
    padding: 70px;

    .container {
      max-width: 1710px;

      .first_col {
        .image_logo {
          .logo_image {
            width: 200px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 820px) {
  .footer {
    padding: 0px;
  }
}
