.contentwithimage {
    margin-top: 8%;
    .container {
        max-width: 1710px;
        .side-image {
            // img {
            //     max-width: 840px;
            //     height: 695px;
            // }
        }
        .side-content {
            .heading_content {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 56px;
                line-height: 75px;
                color: #000000;
            }
            .para_content {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                color: #000000;
                margin-top: 5%;
            }
            Button {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
                align-items: center;
                padding: 13px 21px;
                background: #000000;
                border-radius: 25px;
                border: none;
                margin-top: 5%;
                .icon {
                    width: 36.5px;
                    height: 17px;
                }
            }
        }
    }
}
@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
    .contentwithimage {
        .container {
            .side-content {
                .heading_content {
                    font-size: 36px;
                    line-height: 46px;
                }
            }
        }
    }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1300px) {
    .contentwithimage {
        .container {
            .side-content {
                .heading_content {
                    font-size: 26px;
                    line-height: 36px;
                }
            }
        }
    }
}
@media screen and (max-device-width: 768px) {
    .contentwithimage {
        .container {
            .side-content {
                .heading_content {
                    font-size: 35px;
                    line-height: 46px;
                }
            }
        }
    }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
    .contentwithimage {
        .container {
            .side-content {
                .heading_content {
                    font-size: 30px;
                    line-height: 40px;
                }
                .btn_content {
                    text-align: center;
                }
            }
        }
    }
}
