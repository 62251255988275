.LetsCustomizeYourWall {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }

  .choose_here {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .link_choice {
    padding: 1.5rem 0rem;
    text-decoration: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-right: 50px;
  }

  .row {
    --bs-gutter-x: 0rem;
  }

  .for_scroll {
    height: 400px;
    overflow-y: scroll;
  }

  .content_here {
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    .V-Groov {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #9e9e9e;
    }

    .V-Groov-icon {
      color: #000000;
    }
  }

  .select_products_short {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #9e9e9e;
    border-radius: 5px;
    padding: 5px;
    height: 400px;
    overflow-y: scroll;

    ul {
      padding: 0px;
      margin: 0px;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      list-style: none;
      justify-content: space-between;

      li {
        height: 81px;
        flex: 0 0 24%;
        margin-bottom: 10px;

        img {
          width: 81px;
          height: 81px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .WallSelection {
    border: 1px solid gray;
    margin-top: 67px;
    padding: 20px 35px;

    img {
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #535967;
    }
  }

  .button_and_icon new_style {
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    margin: 3% 0%;

    Button {
      width: 261.5px;
      height: 50px;
      left: 250px;
      top: 965px;
      background: #000000;
      border-radius: 25px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;

      .icon {
        margin-left: 10px;
      }
    }

    img {
      margin-left: 10px;
    }
  }
}

@media screen and (max-device-width: 1920px) {
  .LetsCustomizeYourWall {
    .for_scroll {
      height: 300px;
    }

    .select_products_short {
      height: 300px;
    }
  }
}

@media screen and (max-device-width: 1440px) {
  .LetsCustomizeYourWall {
    .for_scroll {
      height: 200px;
    }

    .select_products_short {
      height: 200px;
    }

    .WallSelection {
      padding: 20px 25px;
    }

    .color_mainbox {
      ul {
        li {
          height: 40px;
          max-width: 100px;
        }
      }
    }

    .select_products_short {
      ul {
        li {
          img {
            width: 51px;
            height: 51px;
          }
        }
      }
    }

    .content_here {
      .V-Groov {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-device-width: 1366px) {
  .LetsCustomizeYourWall {
    .content_here {
      .V-Groov {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-device-width: 1024px) {
  .LetsCustomizeYourWall {
    .link_choice {
      padding: 1.5rem 0rem;
      text-decoration: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin-right: 20px;
    }

    .WallSelection {
      padding: 19px 2px;
    }

    .for_scroll {
      height: 200px;
    }

    .select_products_short {
      height: 200px;
    }

    .color_mainbox {
      ul {
        li {
          height: 30px;
        }
      }
    }

    .content_here {
      p {
        font-size: 13px;
      }

      .V-Groov {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-device-width: 820px) {
  .LetsCustomizeYourWall {
    .color_mainbox {
      ul {
        li {
          width: 30px;
        }
      }
    }

    .WallSelection {
      padding: 19px 26px;
    }

    .color_selection {
      margin-top: 50px;
    }

    .color_mainbox {
      ul {
        li {
          height: 50px;
        }
      }
    }

    .content_here {
      p {
        font-size: 16px;
      }

      .V-Groov {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-device-width: 425px) {
  .LetsCustomizeYourWall {
    .choose_here {
      flex-wrap: nowrap;

      .link_choice {
        font-size: 12px;
        margin-right: 7px;
        text-align: center;
      }
    }

    .color_mainbox {
      ul {
        li {
          height: 30px;
        }
      }
    }
  }
}

@media screen and (max-device-width: 375px) {
  .LetsCustomizeYourWall {
    .WallSelection {
      padding: 19px 22px;
    }

    .content_here {
      .V-Groov {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-device-width: 320px) {
  .LetsCustomizeYourWall {
    h2 {
      font-size: 18px;
      line-height: 28px;
    }

    .WallSelection {
      padding: 20px 10px;

      p {
        text-align: start;
        padding: 0px 10px;
      }
    }

    .button_and_icon {
      Button {
        width: 201.5px;
        font-size: 16px;
      }
    }

    .select_products_short {
      ul {
        li {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .color_mainbox {
      ul {
        li {
          height: 30px;
        }
      }
    }

    .content_here {
      .V-Groov {
        font-size: 11px;
      }
    }
  }
}

