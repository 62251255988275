$theme-header-text: rgba(240, 240, 240, 0.5);

// Fonts
$theme-header-font: 13px;
.myheader {
    header {
        .container {
            max-width: 1710px;
            height: 33px;
        }
        .navbar-brand {
            font-size: $theme-header-font;
            color: $theme-header-text;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
        }
        .me-auto {
            margin-left: 7%;
        }
        .nav-link {
            font-size: $theme-header-font;
            color: $theme-header-text;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
        }
        .space-between {
            margin-left: 50px;
        }
        .icon {
            /* Light grey */
            color: $theme-header-text;
        }
    }
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1300px) {
    .myheader {
        header {
            .navbar-brand {
                font-size: 12px;
                line-height: 15px;
            }
            .me-auto {
                margin-left: 2%;
            }
        }
    }
}