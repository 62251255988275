.consultation {
    margin-top: 7%;
    .container {
        max-width: 1710px;
    }
    .image {
        position: relative;
        background-repeat: no-repeat;
        background-position: center bottom;
        text-align: center;
        background-size: cover;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .over {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: rgba(33, 33, 33, 0.6);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        opacity: 1.1;
    }
    .imgtopheading {
        // width: 100%;

        .tophead {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 31px;
            text-align: center;
            color: #ffffff;
        }
        .tittle {
            padding-top: 74px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 56px;
            line-height: 66px;
            text-align: center;
            color: #ffffff;
        }
        .subtittle {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 56px;
            line-height: 66px;
            text-align: center;
            color: #ffffff;
        }
        Button {
            margin-top: 74px;
            align-items: center;
            padding: 13px 21px;
            gap: 10px;
            width: 203.5px;
            height: 50px;
            background: #ffffff;
            border-radius: 25px;
            color: black;
            border: none;
        }
        .icon {
            width: 30.5px;
            height: 17px;
            flex: none;
        }
    }
}
@media screen and (max-width: 1024px) {
    .consultation {
        .imgtopheading {
            .tittle {
                font-size: 41px;
                line-height: 55px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .consultation {
        .imgtopheading {
            .tittle {
                font-size: 35px;
                line-height: 45px;
            }
            .subtittle {
                font-size: 35px;
                line-height: 45px;
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .consultation {
        .imgtopheading {
            Button {
                margin-top: 0px;
            }
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
    .consultation {
        .imgtopheading {
            .tittle {
                font-size: 20px;
                line-height: 35px;
            }
            .subtittle {
                font-size: 20px;
                line-height: 0px;
            }
            Button {
                margin-top: 20px;
            }
        }
    }
}
