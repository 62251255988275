.discover {
  margin-top: 7%;
  .container {
    max-width: 1710px;
    .side_text {
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 56px;
        line-height: 75px;
        color: #000000;
      }
    }
    Button {
      text-align: right;
      padding: 13px 21px;
      background: #000000;
      border-radius: 25px;
      border: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      float: right;
    }
  }
}
@media screen and (max-width: 1024px) {
  .discover {
    .container {
      .side_text {
        p {
          font-size: 41px;
          line-height: 55px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 820px) {
  .discover {
    .container {
      .side_text {
        p {
          font-size: 34px;
          line-height: 55px;
        }
      }
      Button {
        text-align: none;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .discover {
    .container {
      .for_center {
        align-items: center;
        text-align: center;
      }
      Button {
        float: none;
      }
    }
  }
}
