.contentwithimage {
  margin-top: 8%;
}
.contentwithimage .container {
  max-width: 1710px;
}
.contentwithimage .container .side-content .heading_content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 75px;
  color: #000000;
}
.contentwithimage .container .side-content .para_content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  margin-top: 5%;
}
.contentwithimage .container .side-content Button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  align-items: center;
  padding: 13px 21px;
  background: #000000;
  border-radius: 25px;
  border: none;
  margin-top: 5%;
}
.contentwithimage .container .side-content Button .icon {
  width: 36.5px;
  height: 17px;
}

@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
  .contentwithimage .container .side-content .heading_content {
    font-size: 36px;
    line-height: 46px;
  }
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1300px) {
  .contentwithimage .container .side-content .heading_content {
    font-size: 26px;
    line-height: 36px;
  }
}
@media screen and (max-device-width: 768px) {
  .contentwithimage .container .side-content .heading_content {
    font-size: 35px;
    line-height: 46px;
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .contentwithimage .container .side-content .heading_content {
    font-size: 30px;
    line-height: 40px;
  }
  .contentwithimage .container .side-content .btn_content {
    text-align: center;
  }
}
.headerimg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 20;
}
.headerimg .overlay {
  width: 100%;
  height: 820px;
  position: relative;
}
.headerimg .over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 820px;
  background: linear-gradient(259.13deg, rgba(0, 0, 0, 0) 17.64%, rgba(0, 0, 0, 0.5) 49.38%);
}
.headerimg .imgtopheading .container {
  max-width: 1710px;
}
.headerimg .imgtopheading p {
  position: absolute;
  width: 716px;
  height: 225px;
  left: 105px;
  top: 572px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 75px;
  color: #ffffff;
}

@media screen and (min-width: 2560px) {
  .headerimg .imgtopheading p {
    left: 410px;
  }
}
@media screen and (min-device-width: 1700px) and (max-device-width: 2560px) {
  .container {
    max-width: 1710px;
  }
}
@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
  .container {
    max-width: 1710px;
  }
}
@media screen and (max-width: 1024px) {
  .headerimg .imgtopheading p {
    width: 516px;
    font-size: 41px;
    line-height: 55px;
  }
}
@media screen and (max-width: 1440px) {
  .headerimg .imgtopheading p {
    left: 15px;
  }
}
@media screen and (max-width: 768px) {
  .headerimg .imgtopheading p {
    width: 416px;
    font-size: 35px;
    line-height: 45px;
    left: 15px;
  }
}
@media screen and (max-width: 425px) {
  .headerimg .imgtopheading p {
    width: 316px;
    font-size: 25px;
    line-height: 35px;
    left: 15px;
  }
}
@media screen and (max-width: 320px) {
  .headerimg .imgtopheading p {
    width: 290px;
  }
}
.secondheader .container {
  max-width: 1710px;
}
.secondheader .container .navbar {
  background-color: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Inter";
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 20;
}
.secondheader .container .nav-link:hover {
  color: #fff;
}
.secondheader .container .nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}
.secondheader .container .btnarrow {
  border-radius: 25px;
  width: 184.5px;
  height: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  background: #000000;
  border-radius: 25px;
  border: none;
  margin-left: 700px;
}
.secondheader .container .nav-logo {
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 20px;
  flex-grow: 1;
}
.secondheader .container .nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}
.secondheader .container .nav-link {
  padding: 0% 5%;
  color: #000;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter";
}
.secondheader .container .nav-link:hover {
  color: #000000;
}
.secondheader .container .nav-links {
  color: #000000;
  text-decoration: none;
  height: 100%;
}
.secondheader .container .fa-code {
  margin-left: 1rem;
}
.secondheader .container .nav-item {
  line-height: 40px;
  margin-right: 1rem;
}
.secondheader .container .nav-item .active, .secondheader .container .nav-item .dropdown-item:active {
  color: black;
  font-weight: 800;
}
.secondheader .container .nav-icon {
  display: none;
}
.secondheader .dropdown-item.active, .secondheader .dropdown-item:active {
  text-decoration: none;
  background-color: lightslategray;
}
@media screen and (max-device-width: 1440px) {
  .secondheader .container .btnarrow {
    margin-left: 580px;
  }
}
@media screen and (max-device-width: 1366px) {
  .secondheader .container .btnarrow {
    margin-left: 480px;
  }
}
@media screen and (max-device-width: 1024px) {
  .secondheader .container .btnarrow {
    margin-left: 100px;
  }
}
@media screen and (max-width: 960px) {
  .secondheader .container .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .secondheader .container .btnarrow {
    margin-left: 0%;
  }
  .secondheader .container .dropdown-menu {
    position: static !important;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .secondheader .container .dropdown-item {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 500;
    color: #000000;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .secondheader .container .nav-menu.active, .secondheader .container .nav-menu.dropdown-item:active {
    background: #fff;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-bottom: 10px;
  }
  .secondheader .container .nav-item .active, .secondheader .container .nav-item .dropdown-item:active {
    color: black;
    font-weight: 800;
    border: none;
  }
  .secondheader .container .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .secondheader .container .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000000;
    border-top: none;
  }
}
.smallbanner {
  margin-top: 7%;
}
.smallbanner .container {
  max-width: 1710px;
}
.smallbanner .container .side-image h3 {
  margin-top: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}

.container {
  max-width: 1710px;
}

@media screen and (max-width: 1024px) {
  .smallbanner .side-image h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .smallbanner .side-image h3 {
    font-size: 16px;
    line-height: 30px;
  }
}
@media screen and (max-width: 425px) {
  .smallbanner .side-image h3 {
    font-size: 26px;
    line-height: 36px;
    text-align: center;
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .smallbanner .side-image h3 {
    margin-top: 2px;
    margin-bottom: 70px;
  }
}
.myheader header .container {
  max-width: 1710px;
  height: 33px;
}
.myheader header .navbar-brand {
  font-size: 13px;
  color: rgba(240, 240, 240, 0.5);
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}
.myheader header .me-auto {
  margin-left: 7%;
}
.myheader header .nav-link {
  font-size: 13px;
  color: rgba(240, 240, 240, 0.5);
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}
.myheader header .space-between {
  margin-left: 50px;
}
.myheader header .icon {
  /* Light grey */
  color: rgba(240, 240, 240, 0.5);
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1300px) {
  .myheader header .navbar-brand {
    font-size: 12px;
    line-height: 15px;
  }
  .myheader header .me-auto {
    margin-left: 2%;
  }
}
.consultation {
  margin-top: 7%;
}
.consultation .container {
  max-width: 1710px;
}
.consultation .image {
  position: relative;
  background-repeat: no-repeat;
  background-position: center bottom;
  text-align: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.consultation .over {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(33, 33, 33, 0.6);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  opacity: 1.1;
}
.consultation .imgtopheading .tophead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
}
.consultation .imgtopheading .tittle {
  padding-top: 74px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 66px;
  text-align: center;
  color: #ffffff;
}
.consultation .imgtopheading .subtittle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 66px;
  text-align: center;
  color: #ffffff;
}
.consultation .imgtopheading Button {
  margin-top: 74px;
  align-items: center;
  padding: 13px 21px;
  gap: 10px;
  width: 203.5px;
  height: 50px;
  background: #ffffff;
  border-radius: 25px;
  color: black;
  border: none;
}
.consultation .imgtopheading .icon {
  width: 30.5px;
  height: 17px;
  flex: none;
}

@media screen and (max-width: 1024px) {
  .consultation .imgtopheading .tittle {
    font-size: 41px;
    line-height: 55px;
  }
}
@media screen and (max-width: 768px) {
  .consultation .imgtopheading .tittle {
    font-size: 35px;
    line-height: 45px;
  }
  .consultation .imgtopheading .subtittle {
    font-size: 35px;
    line-height: 45px;
  }
}
@media screen and (max-width: 320px) {
  .consultation .imgtopheading Button {
    margin-top: 0px;
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .consultation .imgtopheading .tittle {
    font-size: 20px;
    line-height: 35px;
  }
  .consultation .imgtopheading .subtittle {
    font-size: 20px;
    line-height: 0px;
  }
  .consultation .imgtopheading Button {
    margin-top: 20px;
  }
}
.discover {
  margin-top: 7%;
}
.discover .container {
  max-width: 1710px;
}
.discover .container .side_text p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 75px;
  color: #000000;
}
.discover .container Button {
  text-align: right;
  padding: 13px 21px;
  background: #000000;
  border-radius: 25px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  float: right;
}

@media screen and (max-width: 1024px) {
  .discover .container .side_text p {
    font-size: 41px;
    line-height: 55px;
  }
}
@media screen and (min-width: 768px) and (max-width: 820px) {
  .discover .container .side_text p {
    font-size: 34px;
    line-height: 55px;
  }
  .discover .container Button {
    text-align: none;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin-top: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .discover .container .for_center {
    align-items: center;
    text-align: center;
  }
  .discover .container Button {
    float: none;
  }
}
.testimonials {
  margin-top: 7%;
}
.testimonials .container {
  max-width: 1710px;
}
.testimonials .container .first_side_content .top_left_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.testimonials .container .first_side_content .top_left_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 75px;
  color: #000000;
}
.testimonials .container .Second_side_content .top_right_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.testimonials .container .Second_side_content .top_right_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}
.testimonials .container .Second_side_content .top_right_icon {
  width: 40px;
  height: 1px;
  background: #000000;
}
.testimonials .container .Second_side_content .top_right_end_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

@media screen and (max-width: 1024px) {
  .testimonials .container .first_side_content .top_left_para {
    font-size: 41px;
    line-height: 55px;
  }
}
@media screen and (max-width: 320px) {
  .testimonials .container .first_side_content .top_left_para {
    font-size: 35px;
    line-height: 45px;
  }
}
.footer {
  margin-top: 7%;
  background: rgba(240, 240, 240, 0.5);
  padding: 70px;
}
.footer .container {
  max-width: 1710px;
}
.footer .container .first_col .legal, .footer .container .first_col .Privacy {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #535967;
}
.footer .container .second_col ul {
  list-style: none;
}
.footer .container .second_col ul li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 10px;
}
.footer .container .second_col ul li h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 20px;
}
.footer .container .third_col {
  margin-top: 0px;
}
.footer .container .third_col h3 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.footer .container .third_col .details {
  width: 220px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.footer .container .third_col .contact, .footer .container .third_col .email {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.footer .container .third_col .icons .space {
  padding-top: 1px;
  margin-right: 10px;
}
.footer .container .fourth_col .data {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}
.footer .container .fourth_col .inputhere {
  margin-bottom: 10%;
}
.footer .container .fourth_col .inputhere .inputcheck {
  border-top: none;
  border-left: none;
  border-right: none;
  background: rgba(240, 240, 240, 0.5);
}
.footer .container .fourth_col .side_btn {
  padding: 5px 15px 5px 15px;
  background: #000000;
  border-radius: 20px;
  border: none;
}
.footer .container .fourth_col .side_btn .icon {
  margin-left: 10px;
}
.footer .container .theend {
  padding-top: 100px;
}
.footer .container .theend p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
  color: #535967;
}

@media screen and (max-width: 1024px) {
  .footer .container .fourth_col .side_btn {
    padding: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width: 912px) {
  .footer .container .second_col ul {
    padding: 0px;
  }
  .footer .container .for_space {
    margin-top: 100px;
  }
}
@media screen and (max-width: 425px) {
  .footer .container .theend .theend_div p {
    font-size: 10px;
    line-height: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .footer {
    margin-top: 7%;
    background: rgba(240, 240, 240, 0.5);
    padding: 70px;
  }
  .footer .container {
    max-width: 1710px;
  }
  .footer .container .first_col .image_logo .logo_image {
    width: 200px;
  }
}
@media screen and (min-width: 320px) and (max-width: 820px) {
  .footer {
    padding: 0px;
  }
}
.carousel-indicators [data-bs-target] {
  display: none;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
  background-color: #f8f8f8;
  position: absolute;
  width: 260px;
  height: 78px;
  left: 0px;
  top: 0px;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-color: #f8f8f8;
  position: absolute;
  width: 260px;
  height: 78px;
  top: 0px;
  right: 252px;
}

.contentwithimage .container .side-image {
  position: relative;
}
.contentwithimage .container .side-image .image_slider {
  position: relative;
}

@media screen and (min-width: 1700px) and (max-width: 1918px) {
  .carousel-control-next-icon {
    right: 312px;
  }
}
@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .carousel-control-next-icon {
    right: 311px;
  }
}
@media screen and (max-width: 1440px) {
  .carousel-control-next-icon {
    right: 176px;
  }
}
@media screen and (max-width: 1366px) {
  .carousel-control-next-icon {
    right: 139px;
  }
}
@media screen and (max-width: 1024px) {
  .carousel-control-prev-icon {
    width: 160px;
  }
  .carousel-control-next-icon {
    width: 160px;
    right: 169px;
  }
}
@media screen and (max-width: 912px) {
  .carousel-control-next-icon {
    right: 570.5px;
  }
}
@media screen and (max-width: 820px) {
  .carousel-control-next-icon {
    right: 476.5px;
  }
}
@media screen and (max-width: 768px) {
  .carousel-control-next-icon {
    right: 424.5px;
  }
}
@media screen and (max-width: 540px) {
  .carousel-control-next-icon {
    right: 200.5px;
  }
}
@media screen and (max-width: 414px) {
  .carousel-control-prev-icon {
    width: 100px;
    height: 58px;
  }
  .carousel-control-next-icon {
    width: 100px;
    height: 58px;
    right: 191.5px;
  }
}
@media screen and (max-width: 412px) {
  .carousel-control-next-icon {
    right: 189.5px;
  }
}
@media screen and (max-width: 393px) {
  .carousel-control-next-icon {
    right: 171.5px;
  }
}
@media screen and (max-width: 375px) {
  .carousel-control-next-icon {
    right: 151.5px;
  }
}
@media screen and (max-width: 360px) {
  .carousel-control-next-icon {
    right: 137.5px;
  }
}
@media screen and (max-width: 320px) {
  .carousel-control-next-icon {
    right: 96.5px;
  }
}
.form-control {
  height: 55px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: #ffffff;
  border: 1px solid #535967;
  color: #000000;
}

textarea {
  width: 840px;
  height: 112px !important;
}

.contactus {
  margin-top: 7%;
}
.contactus .container .custom_form {
  margin-top: 7%;
}
.contactus .container .heading h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.contactus .container .heading h3 {
  margin-top: 1%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.contactus .container .btn_content Button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  align-items: center;
  padding: 13px 21px;
  background: #000000;
  border-radius: 25px;
  border: none;
  margin-top: 5%;
}
.contactus .container .side_content_contactus .imformation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.contactus .container .side_content_contactus .Address {
  margin-top: 20%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: #000000;
}
.contactus .container .side_content_contactus .content, .contactus .container .side_content_contactus span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-top: 10%;
}
.contactus .container .side_content_contactus .icons {
  margin-top: 10%;
}
.contactus .container .side_content_contactus .icons .space {
  margin-right: 5%;
}
.contactus .container .side_content_contactus span {
  color: #a7b241;
}

@media screen and (min-device-width: 320px) and (max-device-width: 912px) {
  .contactus .container .btn_content {
    text-align: center;
  }
  .contactus .container .side_content_contactus {
    text-align: center;
    margin-top: 10%;
  }
  .contactus .container .side_content_contactus .Address {
    margin-top: 5%;
  }
  .contactus .container .side_content_contactus .icons .space {
    margin-right: 2%;
  }
}
.custom_walls {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  perspective: 10em;
  perspective-origin: 50% calc(50% - 2em);
  overflow: hidden;
}
.custom_walls img {
  position: absolute;
  z-index: 100;
}
.custom_walls .scene {
  position: relative;
  transform-style: preserve-3d;
}
.custom_walls .scene .frontWall, .custom_walls .scene .leftWall, .custom_walls .scene .rightWall {
  position: absolute;
  width: 20em;
  height: 20em;
  top: -16em;
  left: -10em;
  transform: translateZ(-10em);
}
.custom_walls .scene .rightWall {
  left: 0;
  transform: rotateY(90deg);
}
.custom_walls .scene .leftWall {
  left: -20em;
  transform: rotateY(-90deg);
}
.custom_walls .scene .floor {
  background-position: 1em 0, 1em 0, 0 0, 0 0;
  background-size: 1em 1em;
  background-repeat: repeat;
  width: 20em;
  height: 20em;
  top: 1em;
  z-index: 0;
  position: absolute;
  transform: translate(-50%, -50%) rotateX(90deg) translateZ(-3em);
}
.custom_walls .scene .ceiling {
  width: 20em;
  height: 20em;
  top: 5em;
  left: 0;
  position: absolute;
  transform: translate(-50%, -50%) rotateX(90deg) translateZ(15em);
}

.category_img {
  height: auto;
  margin: 30px auto;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.category_img img.main_img {
  z-index: 99;
  position: relative;
}
.category_img .pattern_change {
  opacity: 0.5;
  mix-blend-mode: hard-light;
  filter: drop-shadow(-1px 1px 0px rgba(255, 255, 255, 0.6));
  z-index: 9;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 972px;
  height: 608px;
}

.color_mainbox ul::-webkit-scrollbar, .select_products ul::-webkit-scrollbar {
  width: 0px;
}

.custom_Sound_Pannel {
  margin-top: 7%;
}
.custom_Sound_Pannel .container h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.custom_Sound_Pannel .container .Choose_color p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.custom_Sound_Pannel .container .Choose_color .column_color {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  padding: 10px;
}

.color_mainbox ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex: 1 1;
  overflow: auto;
  margin-bottom: 40px;
  max-height: 400px;
  flex-wrap: wrap;
  list-style: none;
}
.color_mainbox ul li {
  height: 67px;
  flex: 0 0 100px;
  margin-bottom: 10px;
}

.Choose_color .content .side_content Button, .Choose_color .contactus .container .side_content_contactus span .side_content Button, .contactus .container .side_content_contactus .Choose_color span .side_content Button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #535967;
  background-color: #fff;
  border: none;
  border-bottom: #000;
}
.Choose_color .content .side_content Button:hover, .Choose_color .contactus .container .side_content_contactus span .side_content Button:hover, .contactus .container .side_content_contactus .Choose_color span .side_content Button:hover {
  color: #fff;
  background-color: #030760;
  border-color: #fff;
}
.Choose_color .content .side_content .btn_select, .Choose_color .contactus .container .side_content_contactus span .side_content .btn_select, .contactus .container .side_content_contactus .Choose_color span .side_content .btn_select {
  color: #a7b241;
  font-weight: 600;
  border-bottom: 1px solid #a7b241;
}

.select_products ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  height: 400px;
  overflow: auto;
  margin-bottom: 40px;
}
.select_products ul li {
  height: auto;
  flex: 0 0 32%;
  margin-bottom: 2%;
  height: 200px;
}
.select_products ul li img {
  width: 100%;
  height: 70%;
  object-fit: cover;
  object-position: center;
}

canvas {
  width: 100% !important;
  height: 615px !important;
}

.main_div.home_oofice {
  width: 100%;
  max-width: 984px;
}

.custom_Sound_Pannel .container .Choose_color .select_products .product_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

@media screen and (max-device-width: 1024px) {
  .color_mainbox ul li {
    height: 37px;
  }
  .custom_walls .scene .ceiling {
    top: 9em;
  }
  .Choose_color .content .side_content Button, .Choose_color .contactus .container .side_content_contactus span .side_content Button, .contactus .container .side_content_contactus .Choose_color span .side_content Button {
    font-size: 12px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 820px) {
  .select_products ul li img {
    width: 100%;
    height: 50%;
  }
}
@media screen and (max-device-width: 320px) {
  .custom_Sound_Pannel .container .Choose_color .select_products .product_name {
    font-size: 10px;
    line-height: 10px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 375px) {
  .Choose_color .content .side_content, .Choose_color .contactus .container .side_content_contactus span .side_content, .contactus .container .side_content_contactus .Choose_color span .side_content {
    display: flex;
  }
  .Choose_color .content .side_content Button, .Choose_color .contactus .container .side_content_contactus span .side_content Button, .contactus .container .side_content_contactus .Choose_color span .side_content Button {
    font-size: 11px;
  }
}
@media screen and (max-device-width: 1440px) {
  .custom_walls .scene .ceiling {
    top: 7.5em;
  }
}
.LetsCustomizeYourWall h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.LetsCustomizeYourWall .choose_here {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.LetsCustomizeYourWall .link_choice {
  padding: 1.5rem 0rem;
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-right: 50px;
}
.LetsCustomizeYourWall .row {
  --bs-gutter-x: 0rem;
}
.LetsCustomizeYourWall .for_scroll {
  height: 400px;
  overflow-y: scroll;
}
.LetsCustomizeYourWall .content_here p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.LetsCustomizeYourWall .content_here .V-Groov {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #9e9e9e;
}
.LetsCustomizeYourWall .content_here .V-Groov-icon {
  color: #000000;
}
.LetsCustomizeYourWall .select_products_short {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  padding: 5px;
  height: 400px;
  overflow-y: scroll;
}
.LetsCustomizeYourWall .select_products_short ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
}
.LetsCustomizeYourWall .select_products_short ul li {
  height: 81px;
  flex: 0 0 24%;
  margin-bottom: 10px;
}
.LetsCustomizeYourWall .select_products_short ul li img {
  width: 81px;
  height: 81px;
  object-fit: cover;
  object-position: center;
}
.LetsCustomizeYourWall .WallSelection {
  border: 1px solid gray;
  margin-top: 67px;
  padding: 20px 35px;
}
.LetsCustomizeYourWall .WallSelection p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #535967;
}
.LetsCustomizeYourWall .button_and_icon new_style {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  margin: 3% 0%;
}
.LetsCustomizeYourWall .button_and_icon new_style Button {
  width: 261.5px;
  height: 50px;
  left: 250px;
  top: 965px;
  background: #000000;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.LetsCustomizeYourWall .button_and_icon new_style Button .icon {
  margin-left: 10px;
}
.LetsCustomizeYourWall .button_and_icon new_style img {
  margin-left: 10px;
}

@media screen and (max-device-width: 1920px) {
  .LetsCustomizeYourWall .for_scroll {
    height: 300px;
  }
  .LetsCustomizeYourWall .select_products_short {
    height: 300px;
  }
}
@media screen and (max-device-width: 1440px) {
  .LetsCustomizeYourWall .for_scroll {
    height: 200px;
  }
  .LetsCustomizeYourWall .select_products_short {
    height: 200px;
  }
  .LetsCustomizeYourWall .WallSelection {
    padding: 20px 25px;
  }
  .LetsCustomizeYourWall .color_mainbox ul li {
    height: 40px;
    max-width: 100px;
  }
  .LetsCustomizeYourWall .select_products_short ul li img {
    width: 51px;
    height: 51px;
  }
  .LetsCustomizeYourWall .content_here .V-Groov {
    font-size: 14px;
  }
}
@media screen and (max-device-width: 1366px) {
  .LetsCustomizeYourWall .content_here .V-Groov {
    font-size: 12px;
  }
}
@media screen and (max-device-width: 1024px) {
  .LetsCustomizeYourWall .link_choice {
    padding: 1.5rem 0rem;
    text-decoration: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-right: 20px;
  }
  .LetsCustomizeYourWall .WallSelection {
    padding: 19px 2px;
  }
  .LetsCustomizeYourWall .for_scroll {
    height: 200px;
  }
  .LetsCustomizeYourWall .select_products_short {
    height: 200px;
  }
  .LetsCustomizeYourWall .color_mainbox ul li {
    height: 30px;
  }
  .LetsCustomizeYourWall .content_here p {
    font-size: 13px;
  }
  .LetsCustomizeYourWall .content_here .V-Groov {
    font-size: 10px;
  }
}
@media screen and (max-device-width: 820px) {
  .LetsCustomizeYourWall .color_mainbox ul li {
    width: 30px;
  }
  .LetsCustomizeYourWall .WallSelection {
    padding: 19px 26px;
  }
  .LetsCustomizeYourWall .color_selection {
    margin-top: 50px;
  }
  .LetsCustomizeYourWall .color_mainbox ul li {
    height: 50px;
  }
  .LetsCustomizeYourWall .content_here p {
    font-size: 16px;
  }
  .LetsCustomizeYourWall .content_here .V-Groov {
    font-size: 16px;
  }
}
@media screen and (max-device-width: 425px) {
  .LetsCustomizeYourWall .choose_here {
    flex-wrap: nowrap;
  }
  .LetsCustomizeYourWall .choose_here .link_choice {
    font-size: 12px;
    margin-right: 7px;
    text-align: center;
  }
  .LetsCustomizeYourWall .color_mainbox ul li {
    height: 30px;
  }
}
@media screen and (max-device-width: 375px) {
  .LetsCustomizeYourWall .WallSelection {
    padding: 19px 22px;
  }
  .LetsCustomizeYourWall .content_here .V-Groov {
    font-size: 13px;
  }
}
@media screen and (max-device-width: 320px) {
  .LetsCustomizeYourWall h2 {
    font-size: 18px;
    line-height: 28px;
  }
  .LetsCustomizeYourWall .WallSelection {
    padding: 20px 10px;
  }
  .LetsCustomizeYourWall .WallSelection p {
    text-align: start;
    padding: 0px 10px;
  }
  .LetsCustomizeYourWall .button_and_icon Button {
    width: 201.5px;
    font-size: 16px;
  }
  .LetsCustomizeYourWall .select_products_short ul li img {
    width: 40px;
    height: 40px;
  }
  .LetsCustomizeYourWall .color_mainbox ul li {
    height: 30px;
  }
  .LetsCustomizeYourWall .content_here .V-Groov {
    font-size: 11px;
  }
}
@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
  body {
    overflow-x: hidden;
  }
}

