@import "../css/_contentandimage.scss";
@import "../css/_headerimg.scss";
@import "../css/_secondpartheader.scss";
@import "../css/_smallbanners.scss";
@import "../css/_topheader.scss";
@import "../css/_consultation.scss";
@import "../css/_discover.scss";
@import "../css/_testimonials.scss";
@import "../css/_footer.scss";
@import "../css/_categories.scss";
@import "../css/_contactus.scss";
@import "../css/_customwalls.scss";
@import "../css/_LetsCustomizeYourWall.scss";

@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
  body {
    overflow-x: hidden;
  }
}



