$font-family: "Inter";
$font-weight: 500;
$font-size: 20px;
$text-color: #000000;
.secondheader {
  .container {
    max-width: 1710px;
    .navbar {
      background-color: #fff;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size;
      font-family: $font-family;
      font-weight: $font-weight;
      position: sticky;
      top: 0;
      z-index: 20;
    }
    .nav-link:hover {
      color: #fff;
    }
    .nav-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80px;
    }
    .btnarrow {
      border-radius: 25px;
      width: 184.5px;
      height: 50px;
      font-family: $font-family;
      font-style: normal;
      font-weight: $font-weight;
      font-size: $font-size;
      color: #ffffff;
      background: #000000;
      border-radius: 25px;
      border: none;
      margin-left: 700px;
    }

    .nav-logo {
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      font-size: $font-size;
      flex-grow: 1;
    }

    .nav-menu {
      display: flex;
      list-style: none;
      text-align: center;
      margin-right: 2rem;
    }
    .nav-link {
      padding: 0% 5%;
      color: #000;
      font-style: normal;
      font-size: $font-size;
      font-weight: $font-weight;
      font-family: $font-family;
    }
    .nav-link:hover {
      color: #000000;
    }
    .nav-links {
      color: $text-color;
      text-decoration: none;
      // padding: 0.5rem 1rem;
      height: 100%;
      // border-bottom: 3px solid transparent;
    }
    .fa-code {
      margin-left: 1rem;
    }

    .nav-item {
      line-height: 40px;
      margin-right: 1rem;
    }

    .nav-item .active {
      color: black;
      font-weight: 800;
    }

    .nav-icon {
      display: none;
    }
  }
  .dropdown-item.active {
    text-decoration: none;
    background-color: lightslategray;
  }
  .dropdown-item:active {
    @extend .dropdown-item, .active
  }
}

@media screen and (max-device-width: 1440px) {
  .secondheader {
    .container {
      .btnarrow {
        margin-left: 580px;
      }
    }
  }
}
@media screen and (max-device-width: 1366px) {
  .secondheader {
    .container {
      .btnarrow {
        margin-left: 480px;
      }
    }
  }
}

@media screen and (max-device-width: 1024px) {
  .secondheader {
    .container {
      .btnarrow {
        margin-left: 100px;
      }
    }
  }
}
@media screen and (max-device-width: 820px) {
}
@media screen and (max-width: 960px) {
  .secondheader {
    .container {
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
      }
      .btnarrow {
        margin-left: 0%;
      }
      .dropdown-menu {
        position: static !important;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 50px;
      }
      .dropdown-item {
        display: block;
        width: 100%;
        // padding: 0.25rem 1rem;
        clear: both;
        font-weight: $font-weight;
        color: #000000;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
      }

      .nav-menu.active {
        background: #fff;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        padding-bottom: 10px;
      }
      .nav-item .active {
        color: black;
        font-weight: 800;
        border: none;
      }
      .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
      }

      .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000000;
        border-top: none;
      }
    }
  }
}
