.carousel-indicators [data-bs-target] {
  display: none;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
  background-color: #f8f8f8;
  position: absolute;
  width: 260px;
  height: 78px;
  left: 0px;
  top: 0px;
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-color: #f8f8f8;
  position: absolute;
  width: 260px;
  height: 78px;
  top: 0px;
  right: 252px;
}
.contentwithimage {
  .container {
    .side-image {
      position: relative;

      .image_slider {
        position: relative;
      }
    }
  }
}
@media screen and (min-width: 1700px) and (max-width: 1918px) {
  .carousel-control-next-icon {
    right: 312px;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .carousel-control-next-icon {
    right: 311px;
  }
}
@media screen and (max-width: 1440px) {
  .carousel-control-next-icon {
    right: 176px;
  }
}
@media screen and (max-width: 1366px) {
  .carousel-control-next-icon {
    right: 139px;
  }
}

@media screen and (max-width: 1024px) {
  .carousel-control-prev-icon {
    width: 160px;
  }
  .carousel-control-next-icon {
    width: 160px;
    right: 169px;
  }
}
@media screen and (max-width: 912px) {
  .carousel-control-next-icon {
    right: 570.5px;
  }
}

@media screen and (max-width: 820px) {
  .carousel-control-next-icon {
    right: 476.5px;
  }
}
@media screen and (max-width: 768px) {
  .carousel-control-next-icon {
    right: 424.5px;
  }
}
@media screen and (max-width: 540px) {
  .carousel-control-next-icon {
    right: 200.5px;
  }
}
@media screen and (max-width: 414px) {
  .carousel-control-prev-icon {
    width: 100px;
    height: 58px;
  }
  .carousel-control-next-icon {
    width: 100px;
    height: 58px;
    right: 191.5px;
  }
}
@media screen and (max-width: 412px) {
  .carousel-control-next-icon {
    right: 189.5px;
  }
}
@media screen and (max-width: 393px) {
  .carousel-control-next-icon {
    right: 171.5px;
  }
}
@media screen and (max-width: 375px) {
  .carousel-control-next-icon {
    right: 151.5px;
  }
}
@media screen and (max-width: 360px) {
  .carousel-control-next-icon {
    right: 137.5px;
  }
}
@media screen and (max-width: 320px) {
  .carousel-control-next-icon {
    right: 96.5px;
  }
}

// @media screen and (max-width: 390px) {
//     .carousel-control-next-icon {
//         right: 167.5px;
//     }
// }

// @media screen and (max-width: 425px) {
//     .carousel-control-prev-icon {
//         width: 100px;
//         height: 58px;
//     }
//     .carousel-control-next-icon {
//         width: 100px;
//         height: 58px;
//         right: 201.5px;
//     }
// }
