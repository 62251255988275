.smallbanner {
  margin-top: 7%;

  .container {
    max-width: 1710px;


    .side-image {
      h3 {
        margin-top: 25px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        color: #000000;
      }
    }
  }
}

.container {
  max-width: 1710px;
}

@media screen and (max-width: 1024px) {
  .smallbanner {
    .side-image {
      h3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .smallbanner {
    .side-image {
      h3 {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .smallbanner {
    .side-image {
      h3 {
        font-size: 26px;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .smallbanner {
    .side-image {
      h3 {
        margin-top: 2px;
        margin-bottom: 70px;
      }
    }
  }
}
