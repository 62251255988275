.form-control {
  height: 55px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: #ffffff;
  border: 1px solid #535967;
  color: #000000;
}

textarea {
  width: 840px;
  height: 112px !important;
}

.contactus {
  margin-top: 7%;

  .container {
    .custom_form {
      margin-top: 7%;
    }

    .heading {
      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
      }

      h3 {
        margin-top: 1%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }

    .btn_content {
      Button {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        align-items: center;
        padding: 13px 21px;
        background: #000000;
        border-radius: 25px;
        border: none;
        margin-top: 5%;
      }
    }

    .side_content_contactus {
      .imformation {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
      }

      .Address {
        margin-top: 20%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 22px;
        color: #000000;
      }

      .content {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #000000;
        margin-top: 10%;
      }

      .icons {
        margin-top: 10%;

        .space {
          margin-right: 5%;
        }
      }

      span {
        @extend .content;
        color: #a7b241;
      }
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 912px) {
  .contactus {
    .container {
      .btn_content {
        text-align: center;

        Button {
        }
      }

      .side_content_contactus {
        text-align: center;
        margin-top: 10%;

        .imformation {
        }

        .Address {
          margin-top: 5%;
        }

        .content {
        }

        .icons {
          .space {
            margin-right: 2%;
          }
        }

        span {
        }
      }
    }
  }
}
