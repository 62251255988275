.testimonials {
    margin-top: 7%;
    .container {
        max-width: 1710px;
        .first_side_content {
            .top_left_heading {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
            }
            .top_left_para {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 56px;
                line-height: 75px;
                color: #000000;
            }
        }
        .Second_side_content {
            .top_right_heading {
                font-family: "Inter";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
            }
            .top_right_para {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                color: #000000;
            }
            .top_right_icon {
                width: 40px;
                height: 1px;
                background: #000000;
            }
            .top_right_end_para {
                font-family: "Inter";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 30px;
                color: #000000;
            }
        }
    }
}
@media screen and (max-width: 1024px) {
    .testimonials {
        .container {
            .first_side_content {
                .top_left_para {
                    font-size: 41px;
                    line-height: 55px;
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .testimonials {
        .container {
            .first_side_content {
                .top_left_para {
                    font-size: 35px;
                    line-height: 45px;
                }
            }
        }
    }
}
